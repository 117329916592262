import {
  ApiError,
  ParcelQuery,
  ParcelQueryRequest,
  ParcelQueryResponse,
  ParcelsService,
} from "../openapi-typescript-axios";
import React, {
  createContext,
  Dispatch,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";
import { useFavoriteParcelsDispatch } from "./FavoriteParcelsContext";
import { enqueueSnackbar } from "notistack";
import { useCurrentLoggedInUser } from "./CurrentLoggedInUser";

export const defaultParcelQuery: ParcelQuery = {
  min_latitude: 42.22788,
  max_latitude: 42.40082,
  min_longitude: -71.191113,
  max_longitude: -70.849455,
  min_price: undefined,
  max_price: undefined,
  min_beds: undefined,
  min_baths: undefined,
  min_parking_spots: undefined,
  must_have_garage_parking: false,
  must_have_guest_house: false,
  must_have_outdoor_shower: false,
  min_lot_size: 0,
  max_hoa_fee_per_month: undefined,
  view: false,
  patio: false,
  ev_charger: false,
  waterfront: false,
  has_pool: false,
  max_miles_to_train_station: undefined,
  keywords: "",
  show_favorites_only: false,
  has_virtual_tour: false,
  has_upcoming_open_house: false,
  parcel_types: [],
};

function parcelQueryReducer(draft: ParcelQuery, action: any): ParcelQuery {
  let toRet: ParcelQuery = draft;
  switch (action.type) {
    case "update":
      toRet = { ...toRet, ...action.payload };
      break;
    case "reset":
      toRet = {
        ...toRet,
        min_price: undefined,
        max_price: undefined,
        min_beds: undefined,
        min_baths: undefined,
        min_parking_spots: undefined,
        must_have_garage_parking: false,
        must_have_guest_house: false,
        must_have_outdoor_shower: false,
        min_lot_size: 0,
        max_hoa_fee_per_month: undefined,
        view: false,
        patio: false,
        ev_charger: false,
        waterfront: false,
        has_pool: false,
        max_miles_to_train_station: undefined,
        keywords: "",
        show_favorites_only: false,
        has_virtual_tour: false,
        has_upcoming_open_house: false,
        parcel_types: [],
      };
      break;
    default:
      break;
  }
  console.debug("parcelQueryReducer: ", toRet);
  return toRet;
}

/**
 * This function retrieves the parcel query from the local storage.
 * The parcel query is stored under the key "parcelQuery".
 *
 * @returns {ParcelQuery | null} The parcel query as a ParcelQuery object if it exists and is a valid object, otherwise null.
 */
function getParcelQueryFromLocalStorage(): ParcelQuery | null {
  const localStorageResult = localStorage.getItem("parcelQuery");
  if (localStorageResult) {
    const ret: ParcelQuery = JSON.parse(localStorageResult);
    if (
      ret.min_latitude &&
      ret.max_latitude &&
      ret.min_longitude &&
      ret.max_longitude
    ) {
      return ret;
    }
  }
  return null;
}

export const ParcelQueryRequestIsFetchingForListOfResultsContext =
  createContext<Dispatch<any>>(() => {
    throw new Error(
      "ParcelQueryRequestIsFetchingForListOfResultsContext not ready",
    );
  });
export const ParcelQueryIsLoadingContext = createContext(false);
export const ParcelQueryResponseContext =
  createContext<ParcelQueryResponse | null>(null);
export const ParcelQueryContext =
  createContext<ParcelQuery>(defaultParcelQuery);
export const ParcelQueryDispatchContext = createContext<Dispatch<any>>(() => {
  throw new Error("ParcelQueryDispatchContext not ready");
});

/**
 * This is a React component that provides the parcel query and its response to its children.
 * It uses the useReducer hook to manage the state of the parcel query and the useState hook to manage the state of the parcel query response.
 * It also uses the useEffect hook to perform side effects, such as saving the parcel query to local storage and fetching parcels from the ParcelsService.
 *
 * @param {Object} props The properties passed to the component.
 * @param {React.ReactNode} props.children The child components to which the parcel query and its response are provided.
 * @returns {React.ReactNode} The child components wrapped with the parcel query and its response providers.
 */
export default function ParcelQueryAndParcelQueryResponseProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const DEBOUNCE_TIME_TO_WAIT_BEFORE_SEARCH_MILLIS = 200;

  const [isLoading, setIsLoading] = useState(false);

  const [isFetchingForListOfResults, setIsFetchingForListOfResults] =
    useState(true);

  const [parcelQuery, parcelQueryDispatch] = useReducer(
    parcelQueryReducer,
    getParcelQueryFromLocalStorage() ?? defaultParcelQuery,
  );
  const [parcelQueryResponse, setParcelQueryResponse] =
    useState<ParcelQueryResponse | null>(null);
  const setFavoriteParcels = useFavoriteParcelsDispatch();

  const currentLoggedInUser = useCurrentLoggedInUser();

  const updateParcelQueryResponseTimeoutId =
    React.useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const previousParcelQueryJSON = localStorage.getItem("parcelQuery");
    const newParcelQueryJSON = JSON.stringify(parcelQuery);

    if (previousParcelQueryJSON === newParcelQueryJSON) {
      console.debug(
        "Parcel query has not changed, not saving to local storage nor fetching new results",
      );
      return;
    }

    let ignoreAfterComponentUnmount = false;
    let parcelQueryRequest: ParcelQueryRequest = {
      parcel_query: parcelQuery,
      expected_columns: isFetchingForListOfResults
        ? [
            "pictures",
            "half_baths",
            "full_baths",
            "beds",
            "address",
            "original_entry_timestamp_utc",
          ]
        : [],
      paginate_results: isFetchingForListOfResults,
      page_num: isFetchingForListOfResults ? 1 : undefined,
    };

    if (updateParcelQueryResponseTimeoutId.current) {
      clearTimeout(updateParcelQueryResponseTimeoutId.current);
      updateParcelQueryResponseTimeoutId.current = null;
    }

    updateParcelQueryResponseTimeoutId.current = setTimeout(() => {
      localStorage.setItem("parcelQuery", newParcelQueryJSON);

      setIsLoading(true);

      ParcelsService.getParcels(parcelQueryRequest)
        .then((parcelQueryResponse) => {
          if (!ignoreAfterComponentUnmount) {
            setParcelQueryResponse(parcelQueryResponse);

            console.debug(
              "Going to set favorite parcels to: ",
              parcelQueryResponse.favorite_parcel_ids ?? [],
            );

            setFavoriteParcels({
              type: "set",
              payload: parcelQueryResponse.favorite_parcel_ids ?? [],
            });
          } else {
            console.debug(
              "Ignoring response for parcel query response... (unmounted)",
            );
          }
        })
        .catch((err) => {
          console.error("Error getting parcels: ", err);
          if (err instanceof ApiError) {
            enqueueSnackbar(
              "Error getting listings... Our bad -- we're looking into it...",
              {
                preventDuplicate: true,
              },
            );
          } else {
            enqueueSnackbar(
              "Error getting listings... Check your internet connection...",
              {
                preventDuplicate: true,
              },
            );
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    }, DEBOUNCE_TIME_TO_WAIT_BEFORE_SEARCH_MILLIS);

    return () => {
      // cleanup
      ignoreAfterComponentUnmount = true;
      if (updateParcelQueryResponseTimeoutId.current) {
        clearTimeout(updateParcelQueryResponseTimeoutId.current);
        updateParcelQueryResponseTimeoutId.current = null;
      }
    };
  }, [
    parcelQuery,
    setFavoriteParcels,
    currentLoggedInUser,
    isFetchingForListOfResults,
  ]);

  return (
    <ParcelQueryIsLoadingContext.Provider value={isLoading}>
      <ParcelQueryRequestIsFetchingForListOfResultsContext.Provider
        value={setIsFetchingForListOfResults}
      >
        <ParcelQueryContext.Provider value={parcelQuery}>
          <ParcelQueryDispatchContext.Provider value={parcelQueryDispatch}>
            <ParcelQueryResponseContext.Provider value={parcelQueryResponse}>
              {children}
            </ParcelQueryResponseContext.Provider>
          </ParcelQueryDispatchContext.Provider>
        </ParcelQueryContext.Provider>
      </ParcelQueryRequestIsFetchingForListOfResultsContext.Provider>
    </ParcelQueryIsLoadingContext.Provider>
  );
}

export function useParcelQueryDispatch() {
  return useContext(ParcelQueryDispatchContext);
}

export function useParcelQuery() {
  return useContext(ParcelQueryContext);
}

export function useParcelQueryResponse() {
  return useContext(ParcelQueryResponseContext);
}

export function useIsLoadingParcelQuery() {
  return useContext(ParcelQueryIsLoadingContext);
}

export function useParcelQueryRequestIsFetchingForListOfResults() {
  return useContext(ParcelQueryRequestIsFetchingForListOfResultsContext);
}
