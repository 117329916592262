import { Avatar, Menu, rem } from "@mantine/core";
import { googleLogout, useGoogleLogin } from "@react-oauth/google";
import * as Sentry from "@sentry/react";
import { IconLogout } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import GoogleButton from "react-google-button";
import { AppStoreButton, GooglePlayButton } from "react-mobile-app-button";

import {
  useCurrentLoggedInUser,
  useSetCurrentLoggedInUser,
} from "../contexts/CurrentLoggedInUser";
import {
  useParcelQuery,
  useParcelQueryDispatch,
} from "../contexts/ParcelQueryAndParcelQueryResponseProvider";
import {
  OauthGoogleService,
  ParcelListsService,
  SessionService,
} from "../openapi-typescript-axios";

export default function Header() {
  const [userProfilePicture, setUserProfilePicture] = useState<
    string | null | undefined
  >(null);

  const currentLoggedInUser = useCurrentLoggedInUser();
  const setCurrentLoggedInUser = useSetCurrentLoggedInUser();

  const setParcelQuery = useParcelQueryDispatch();
  const parcelQuery = useParcelQuery();

  const login = useGoogleLogin({
    flow: "auth-code",
    redirect_uri: "http://localhost:3000",
    onSuccess: (tokenResponse) => {
      if (tokenResponse.code == null) {
        console.error("Failed Google Login");
        Sentry.captureMessage("Google credential was null...despite success.");
        return;
      }

      console.info("Succeeded login: ", tokenResponse);

      // Get the current URL base
      const redirectUri = window.location.origin;

      OauthGoogleService.verifyGoogleAuthCode({
        auth_code: tokenResponse.code,
        redirect_uri: redirectUri,
      }).then((response) => {
        console.info("verifyGoogleToken response: ", response);

        let registered_user = response.registered_user;

        if (registered_user == null) {
          console.error("Failed Google Login");
          Sentry.captureMessage(
            "Google registered user was null...despite success."
          );
          return;
        }
        setCurrentLoggedInUser({
          registered_user,
        });
        setUserProfilePicture(response.picture_url);

        ParcelListsService.getLists()
          .then((response) => {
            console.info("getLists response: ", response);
          })
          .catch((error) => {
            Sentry.captureException(error);
            console.error(error);
          });
      });
    },
  });

  useEffect(() => {
    // Check if the user is already logged in
    SessionService.refreshSession(null)
      .then((response) => {
        console.info("getCurrentUser response: ", response);

        if (response.registered_user == null) {
          console.error("Failed Google Login");
          Sentry.captureMessage(
            "Google registered user was null...despite success."
          );
          return;
        }

        setCurrentLoggedInUser(response.registered_user);
        setUserProfilePicture(response.picture_url);
      })
      .catch((error) => {
        Sentry.captureException(error);
      });
  }, [setCurrentLoggedInUser]);

  function logout() {
    googleLogout();
    SessionService.logoutUser()
      .then((response) => {
        console.info("logoutUser response: ", response);

        setParcelQuery({
          type: "update",
          payload: {
            parcel_query: {
              ...parcelQuery,
              show_favorites_only: false,
            },
          },
        });

        setCurrentLoggedInUser(null);
        console.info("Logged out");
      })
      .catch((error) => {
        Sentry.captureException(error);
      });
  }

  return (
    <header>
      <div className={"flex flex-row bg-green-700"}>
        <div className={"flex grow flex-col items-center sm:flex-row"}>
          <div className={" sm:flex"}>
            <a href={"/"}>
              <img
                className={"my-3 h-28 px-5"}
                src={"/wrenLogoNew.png"}
                alt={"Wren Logo"}
              />
            </a>
          </div>
          <div className="flex grow items-center justify-center space-x-10">
            <GooglePlayButton
              url={
                "https://play.google.com/store/apps/details?id=com.housewren.wren"
              }
              theme="dark"
              width={200}
            />

            <AppStoreButton
              url={"https://apps.apple.com/be/app/house-wren/id1490316938"}
              theme="dark"
              width={200}
            />
          </div>
          <div className="">
            <div className="p-0 m-0">
              {currentLoggedInUser ? (
                <div>
                  <Menu shadow="md" width={200}>
                    <Menu.Target>
                      <div
                        className={"cursor-pointer rounded-full bg-white p-1"}
                      >
                        <Avatar
                          size="lg"
                          src={userProfilePicture ?? ""}
                          alt={
                            [
                              currentLoggedInUser.first_name,
                              currentLoggedInUser.last_name,
                            ].join(" ") ?? ""
                          }
                        />
                      </div>
                    </Menu.Target>
                    <Menu.Dropdown>
                      <Menu.Label>User Options</Menu.Label>
                      {/*<Menu.Item*/}
                      {/*  leftSection={*/}
                      {/*    <IconSettings*/}
                      {/*      style={{ width: rem(14), height: rem(14) }}*/}
                      {/*    />*/}
                      {/*  }*/}
                      {/*>*/}
                      {/*  Settings*/}
                      {/*</Menu.Item>*/}

                      <Menu.Divider />

                      <Menu.Item
                        color="red"
                        leftSection={
                          <IconLogout
                            style={{ width: rem(14), height: rem(14) }}
                          />
                        }
                        onClick={() => logout()}
                      >
                        Logout
                      </Menu.Item>
                    </Menu.Dropdown>
                  </Menu>
                </div>
              ) : (
                <GoogleButton
                  className="scale-75 transform"
                  onClick={() => {
                    login();
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}
