import React from "react";
import { ParcelMarker } from "./markers/ParcelMarker";
import { ClusterMarker } from "./markers/ClusterMarker";
import { useFavoriteParcels } from "../../contexts/FavoriteParcelsContext";
import { ListingTypesEnum } from "../../openapi-typescript-axios";
import { formatPriceForMapMarkers } from "../../utils/PriceFormatters";
import { useParcelQueryResponse } from "../../contexts/ParcelQueryAndParcelQueryResponseProvider";

/**
 * Custom React hook that generates markers for a map based on the parcel query response.
 *
 * @param {Object} params - The parameters for the hook.
 * @param {boolean} params.isDarkMode - Whether the map is in dark mode.
 *
 * @returns {React.ReactNode[]} An array of React nodes representing the markers, or null if there are no markers.
 */
const useMarkers = ({ isDarkMode }: { isDarkMode: boolean }) => {
  // Use the parcel query response from context
  const parcelQueryResponse = useParcelQueryResponse();
  // Use the favorite parcels from context
  const favoriteParcels = useFavoriteParcels();

  // If there are clusters and no parcels, or if there are no parcels at all, generate cluster markers
  if (
    (parcelQueryResponse?.clusters != null &&
      parcelQueryResponse.clusters.length > 0 &&
      parcelQueryResponse?.parcels == null) ||
    parcelQueryResponse?.parcels?.length === 0
  ) {
    return (parcelQueryResponse?.clusters ?? []).map((parcelCluster) => {
      return (
        <ClusterMarker
          key={parcelCluster.first_parcel_id}
          parcelCluster={parcelCluster}
          isDarkMode={isDarkMode}
          isRentals={
            parcelCluster.listing_types_enum === ListingTypesEnum.RENTALS
          }
        />
      );
    });
  }
  // If there are parcels, generate parcel markers
  else if (
    parcelQueryResponse?.parcels != null &&
    parcelQueryResponse.parcels.length > 0
  ) {
    return parcelQueryResponse?.parcels.map((parcel) => {
      if (parcel.latitude == null || parcel.longitude == null) return null;

      return (
        <ParcelMarker
          key={parcel.id}
          parcelId={parcel.id}
          lat={parcel.latitude}
          lng={parcel.longitude}
          text={formatPriceForMapMarkers(parcel.list_price, parcel.is_rental)}
          isDarkMode={isDarkMode}
          isFavorite={favoriteParcels.includes(parcel.id)}
          isRental={parcel.is_rental ?? false}
        />
      );
    });
  }

  // If there are no clusters or parcels, return null
  return null;
};

export default useMarkers;
