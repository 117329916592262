import * as Sentry from "@sentry/react";
import MainLayout from "../layouts/MainLayout";
import React from "react";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";

const initSentry = () => {
  Sentry.withErrorBoundary(MainLayout, {
    fallback: <p>an error inside MainLayout has occurred</p>,
  });

  Sentry.init({
    attachStacktrace: true,
    beforeSend(event, _hint) {
      // Remove process.env.REACT_APP_ROUTER_BASENAME from the URL
      // so that we can group errors by the route
      if (process.env.REACT_APP_ROUTER_BASENAME && event.transaction) {
        event.transaction = event.transaction.replace(
          process.env.REACT_APP_ROUTER_BASENAME ?? "",
          "",
        );
      }

      return event;
    },
    beforeSendTransaction(transaction) {
      // Remove process.env.REACT_APP_ROUTER_BASENAME from the URL
      // so that we can group errors by the route
      if (process.env.REACT_APP_ROUTER_BASENAME && transaction.transaction) {
        transaction.transaction = transaction.transaction.replace(
          process.env.REACT_APP_ROUTER_BASENAME ?? "",
          "",
        );
      }

      return transaction;
    },
    enabled: true, // Alternatively: use `process.env.NODE_ENV === 'production'`
    environment: process.env.NODE_ENV,
    dsn: "https://7cfa3bc27ef053eda9e76e95a1ba0160@o243023.ingest.sentry.io/4506579564036096",
    release: process.env.REACT_APP_GIT_SHA, // Here you set the release to the Git SHA
    integrations: [
      new Sentry.BrowserProfilingIntegration(),
      new Sentry.BrowserTracing({
        // See docs for support of different versions of variation of react router
        // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        ),
      }),
      new Sentry.Replay({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      "localhost",
      /^https:\/\/api\.housewren\.app/,
      /^http:\/\/localhost/,
    ],
    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
};

export default initSentry;
