/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Enum for status of adding a parcel to a list.
 */
export enum AddParcelToListStatus {
    ADDED = 'ADDED',
    ALREADY_EXISTS = 'ALREADY_EXISTS',
    LIST_NOT_FOUND = 'LIST_NOT_FOUND',
    PARCEL_NOT_FOUND = 'PARCEL_NOT_FOUND',
}
