/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Used to show what type of listings are in cluster.
 *
 * Map will only ever show one type of listing at a time, so 'both' is not
 * a valid option.
 */
export enum ListingTypesEnum {
    RENTALS = 'rentals',
    SALES = 'sales',
}
