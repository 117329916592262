import React, { useContext } from "react";
import {
  ParcelQueryContext,
  ParcelQueryDispatchContext,
} from "../../contexts/ParcelQueryAndParcelQueryResponseProvider";

/**
 * RentalsOrSalesSelector is a React component that provides a UI for selecting between sales and rentals.
 * It uses the ParcelQueryContext and ParcelQueryDispatchContext to manage the state of the selection.
 */
function RentalsOrSalesSelector() {
  // Get the current parcel query and the function to update it from context
  const parcelQuery = useContext(ParcelQueryContext);
  const setParcelQuery = useContext(ParcelQueryDispatchContext);

  // Define the keys for the sales and rentals options
  const keys = ["Sales", "Rentals"];
  // Create an object mapping the keys to their current status in the parcel query
  const labelToToggleStatus = {
    [keys[0]]: parcelQuery.show_sales_listings,
    [keys[1]]: parcelQuery.show_rental_listings,
  };

  // If neither sales nor rentals are currently selected, update the parcel query to show sales listings
  if (!Object.values(labelToToggleStatus).some(Boolean)) {
    setParcelQuery({
      type: "update",
      payload: {
        ...parcelQuery,
        show_sales_listings: true,
        show_rental_listings: false,
      },
    });
  }

  // Render the UI for selecting between sales and rentals
  return (
    <div className="mt-2 flex flex-row justify-center">
      <div className={"grid grid-cols-2 text-sm"}>
        {keys.map((key) => {
          // Determine whether the current key is active
          const isActive = labelToToggleStatus[key];
          // Render a button for the current key
          return (
            <button
              onClick={(event) => {
                // When the button is clicked, update the parcel query to toggle the status of the current key
                setParcelQuery({
                  type: "update",
                  payload: {
                    ...parcelQuery,
                    show_sales_listings: key === keys[0] ? !isActive : isActive,
                    show_rental_listings:
                      key === keys[1] ? !isActive : isActive,
                  },
                });
                // Prevent the default button click behavior
                event.preventDefault();
              }}
              // Apply CSS classes to the button based on whether it is active
              className={`justify-center border px-4 py-2 hover:cursor-pointer hover:border-indigo-700 hover:text-indigo-700 ${
                isActive
                  ? "bg-sky-400 ring-1 ring-amber-500"
                  : "border-gray-400"
              }`}
              key={key}
            >
              {key}
            </button>
          );
        })}
      </div>
    </div>
  );
}

export default RentalsOrSalesSelector;
