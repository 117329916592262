import { useNavigate } from "react-router-dom";
import WrenBaseMarker from "./WrenBaseMarker";

export function ParcelMarker({
  text,
  lat,
  lng,
  parcelId,
  isDarkMode,
  isFavorite,
  isRental = false,
}: {
  text: string;
  lat: number;
  lng: number;
  parcelId: number;
  isDarkMode: boolean;
  isFavorite: boolean;
  isRental?: boolean;
}) {
  const navigate = useNavigate();

  function handleClickOnMarker(e: google.maps.MapMouseEvent) {
    console.log("handleClickOnMarker called", e);

    e.domEvent.preventDefault();

    if (e.domEvent instanceof MouseEvent) {
      let mouseEvent: MouseEvent = e.domEvent;
      // if meta key was down when click happened
      if (mouseEvent.metaKey) {
        // Open new tab, if CMD key is held down.
        window.open("/listing/" + parcelId, "_blank");
        return;
      }
    }
    navigate("/listing/" + parcelId);
  }

  return WrenBaseMarker({
    lat,
    lng,
    text,
    isDarkMode: isDarkMode,
    isFavorite: isFavorite,
    isRental: isRental,
    handleClickOnMarker: handleClickOnMarker,
  });
}
