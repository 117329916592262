import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useCurrentLoggedInUser } from "../../contexts/CurrentLoggedInUser";
import {
  ParcelQueryContext,
  ParcelQueryDispatchContext,
} from "../../contexts/ParcelQueryAndParcelQueryResponseProvider";
import ParcelTypesSelector from "./ParcelTypesSelector";
import { enqueueSnackbar } from "notistack";
import RentalsOrSalesSelector from "./RentalsOrSalesSelector";
import { ParcelTypeEnum, RegisteredUser } from "../../openapi-typescript-axios";

interface InputFieldProps {
  label: string;
  type: string;
  name: string;
  value: any;
  handleChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => void;
  dropdownOptions?: number[];
  dropdownOptionsSuffixes?: string[];
  placeholder?: string;
  className?: string;
}

const InputField: React.FC<InputFieldProps> = ({
  label,
  type,
  name,
  value,
  handleChange,
  dropdownOptions,
  dropdownOptionsSuffixes,
  placeholder,
  className,
}) => {
  let inputElement = undefined;
  switch (type) {
    case "checkbox":
      inputElement = (
        <input
          type={type}
          name={name}
          checked={value}
          onChange={handleChange}
          style={{
            MozAppearance: "textfield", // For Firefox
          }}
          className={
            className +
            " " +
            "grow cursor-pointer appearance-none border border-solid border-black pr-2 text-right " +
            (["text", "number"].includes(type) ? "h-7 w-full" : "")
          }
        />
      );
      break;
    case "text":
    case "number":
      inputElement = (
        <input
          type={type}
          name={name}
          placeholder={placeholder}
          value={value}
          onChange={handleChange}
          style={{
            MozAppearance: "textfield", // For Firefox
          }}
          className={
            className +
            " " +
            "grow appearance-none border border-solid border-black pr-2 text-right " +
            (["text", "number"].includes(type) ? "h-7 w-full" : "")
          }
        />
      );
      break;
    case "dropdown":
      inputElement = (
        <select
          name={name}
          value={value}
          onChange={handleChange}
          className={
            "appearance-none border-2 border-solid border-black px-5 text-right"
          }
          style={{
            // border: "1px solid #000",
            appearance: "none",
            MozAppearance: "textfield", // For Firefox
          }}
        >
          {(dropdownOptions ?? []).map((option) => {
            let suffix = "";
            if (dropdownOptionsSuffixes) {
              suffix =
                option === 1
                  ? " " + dropdownOptionsSuffixes[0]
                  : " " + dropdownOptionsSuffixes[1];
            }
            return (
              <option value={option} key={option}>
                {option}
                {suffix}
              </option>
            );
          })}
        </select>
      );
  }

  return (
    <div className={"mb-4 flex flex-row"}>
      <div className={"basis-1/2"}>
        <label htmlFor={name}>
          {label}
          {label.endsWith("?") ? "" : ":"}
        </label>
      </div>
      <div className={"flex grow justify-end "}>
        <div className={"grow text-right"}>{inputElement}</div>
      </div>
    </div>
  );
};

function FiltersPanel() {
  const parcelQuery = useContext(ParcelQueryContext);
  const setParcelQuery = useContext(ParcelQueryDispatchContext);
  const navigate = useNavigate();

  const formatPrice = (
    value: string | undefined | null,
    allowEmptyResult: boolean = false,
  ) => {
    if (value === undefined || value === null || value === "") {
      if (allowEmptyResult) {
        return "";
      } else {
        value = "0";
      }
    }

    // Remove non-numeric characters
    const numericValue = value.replace(/\D/g, "");
    // Format the display value
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      maximumFractionDigits: 0,
    }).format(Number(numericValue));
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    const target = event.target as HTMLInputElement;
    const name = event.target.name;
    let value: string | number | boolean | ParcelTypeEnum[] | null | undefined =
      event.target.value;
    const type = event.target.type;

    // It's possible for number fields to have leading zeros.  We don't like that, so
    // get rid of them.
    if (type === "number") {
      value = value.replace(/^0+/, "");
    }

    console.warn("Value is ", value);

    const integerFields = [
      "min_price",
      "max_price",
      "max_hoa_fee_per_month",
      "min_beds",
      "max_beds",
      // TODO(tek): allow 1.5 baths as a number...
      "min_baths",
      "min_parking_spots",
    ];

    const fieldsThatCanBeUndefined = [
      "min_price",
      "max_price",
      "max_hoa_fee_per_month",
      "min_beds",
      "max_beds",
      "min_baths",
      "min_parking_spots",
      "max_miles_to_train_station",
    ];

    const decimalFields = ["max_miles_to_train_station"];

    if (integerFields.includes(name) || decimalFields.includes(name)) {
      // Remove any characters that are not numbers or a decimal point
      value = value.replace(/[^\d.]/g, "");
    }

    if (fieldsThatCanBeUndefined.includes(name) && value === "") {
      value = undefined;
    } else if (integerFields.includes(name)) {
      value = Math.max(0, parseInt(value?.replace(/\D/g, "") || "0"));
    } else if (decimalFields.includes(name)) {
      // If value is 0. followed by any zeros, don't change it
      if (!RegExp(/^0\.0+$/).exec(value)) {
        const maybe_new_value = Math.max(
          0,
          parseFloat(value.replace(/\D/g, "") || "0"),
        );

        // if NaN
        if (isNaN(maybe_new_value)) {
          value = parcelQuery.max_miles_to_train_station ?? 0;
        } else {
          value = maybe_new_value;
        }
      }
    }

    if (target.type === "checkbox") {
      value = target.checked;
    }

    console.warn("Now setting " + name + " to be: ", value);

    const newPayload = {
      ...parcelQuery,
      [name]: value,
    };

    setParcelQuery({
      type: "update",
      payload: newPayload,
    });
  };

  const handleReset = () => {
    setParcelQuery({ type: "reset" });

    enqueueSnackbar("Filters Reset");
  };

  const currentLoggedInUser: RegisteredUser | null = useCurrentLoggedInUser();

  return (
    <div className="grow px-5 py-2">
      <button
        style={{
          backgroundColor: "#48de43",
          color: "#212529",
          border: "1px solid #ced4da",
          borderRadius: "0.25rem",
          padding: "0.375rem 0.75rem",
          fontSize: "1rem",
          lineHeight: 1.5,
          transition:
            "color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
          cursor: "pointer",
          textDecoration: "none",
          textAlign: "center",
          verticalAlign: "middle",
          userSelect: "none",
          outline: "none",
          boxShadow: "0 0.125rem 0.25rem rgba(0,0,0,0.075)",
          margin: "0.375rem",
        }}
        onClick={() =>
          navigate("/list_results/", {
            replace: false,
            preventScrollReset: true,
          })
        }
      >
        List View
      </button>
      <form
        className={""}
        onKeyDown={(event: React.KeyboardEvent) => {
          if (event.key === "Enter") {
            event.preventDefault();
          }
        }}
      >
        <div className="flex flex-row justify-end">
          <button
            type="button"
            onClick={handleReset}
            className="w-1/2 font-bold"
            style={{
              backgroundColor: "#48de43",
              color: "#212529",
              border: "1px solid #ced4da",
              borderRadius: "0.25rem",
              padding: "0.375rem 0.75rem",
              fontSize: "1rem",
              lineHeight: 1.5,
              transition:
                "color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
              cursor: "pointer",
              textDecoration: "none",
              textAlign: "center",
              verticalAlign: "middle",
              userSelect: "none",
              outline: "none",
              boxShadow: "0 0.125rem 0.25rem rgba(0,0,0,0.075)",
              margin: "0.375rem",
            }}
          >
            Reset Filters
          </button>
        </div>

        <RentalsOrSalesSelector />

        <ParcelTypesSelector />

        <div className="py-2 text-xs">
          <InputField
            label={"Min Price"}
            type={"text"}
            name={"min_price"}
            value={formatPrice(parcelQuery.min_price?.toString(), true)}
            handleChange={handleChange}
          />
          <InputField
            label={"Max Price"}
            type={"text"}
            name={"max_price"}
            value={formatPrice(parcelQuery.max_price?.toString(), true)}
            handleChange={handleChange}
          />
          <InputField
            label={"Min Beds"}
            type={"number"}
            name={"min_beds"}
            value={parcelQuery.min_beds}
            handleChange={handleChange}
          />
          <InputField
            label={"Min Baths"}
            type={"number"}
            name={"min_baths"}
            value={parcelQuery.min_baths}
            handleChange={handleChange}
          />
          <InputField
            label={"Min Parking Spots"}
            type={"number"}
            name={"min_parking_spots"}
            value={parcelQuery.min_parking_spots}
            handleChange={handleChange}
          />
          <InputField
            label={"Garage Parking?"}
            type={"checkbox"}
            name={"must_have_garage_parking"}
            value={parcelQuery.must_have_garage_parking}
            handleChange={handleChange}
          />
          <InputField
            label={"Guest House?"}
            type={"checkbox"}
            name={"must_have_guest_house"}
            value={parcelQuery.must_have_guest_house}
            handleChange={handleChange}
          />
          <InputField
            label={"Outdoor Shower?"}
            type={"checkbox"}
            name={"must_have_outdoor_shower"}
            value={parcelQuery.must_have_outdoor_shower}
            handleChange={handleChange}
          />
          <InputField
            label={"Min Lot Size (acres?)"}
            type={"dropdown"}
            name={"min_lot_size"}
            value={parcelQuery.min_lot_size}
            handleChange={handleChange}
            dropdownOptions={[
              0, 0.25, 0.5, 1, 2, 3, 4, 5, 7.5, 10, 20, 50, 100,
            ]}
            dropdownOptionsSuffixes={["acre", "acres"]}
          />
          <InputField
            label={"Max HOA $ / month"}
            type={"text"}
            name={"max_hoa_fee_per_month"}
            value={formatPrice(
              parcelQuery.max_hoa_fee_per_month?.toString(),
              true,
            )}
            handleChange={handleChange}
          />
          <InputField
            label={"View"}
            type={"checkbox"}
            name={"view"}
            value={parcelQuery.view}
            handleChange={handleChange}
          />
          <InputField
            label={"Patio"}
            type={"checkbox"}
            name={"patio"}
            value={parcelQuery.patio}
            handleChange={handleChange}
          />
          <InputField
            label={"EV Charger"}
            type={"checkbox"}
            name={"ev_charger"}
            value={parcelQuery.ev_charger}
            handleChange={handleChange}
          />
          <InputField
            label={"Waterfront"}
            type={"checkbox"}
            name={"waterfront"}
            value={parcelQuery.waterfront}
            handleChange={handleChange}
          />
          <InputField
            label={"Pool"}
            type={"checkbox"}
            name={"has_pool"}
            value={parcelQuery.has_pool}
            handleChange={handleChange}
          />
          <InputField
            label={"Max Miles to Train"}
            type={"number"}
            name={"max_miles_to_train_station"}
            value={parcelQuery.max_miles_to_train_station}
            handleChange={handleChange}
          />
          <InputField
            label={"Keywords"}
            type={"text"}
            name={"keywords"}
            value={parcelQuery.keywords}
            placeholder={"comma, separated, keywords"}
            handleChange={handleChange}
            className={"text-xs"}
          />
          <InputField
            label={"Virtual Tour"}
            type={"checkbox"}
            name={"has_virtual_tour"}
            value={parcelQuery.has_virtual_tour}
            handleChange={handleChange}
          />
          <InputField
            label={"Upcoming Open House"}
            type={"checkbox"}
            name={"has_upcoming_open_house"}
            value={parcelQuery.has_upcoming_open_house}
            handleChange={handleChange}
          />
        </div>

        {currentLoggedInUser != null && (
          <InputField
            label={"Show Favorites Only"}
            type={"checkbox"}
            name={"show_favorites_only"}
            value={parcelQuery.show_favorites_only}
            handleChange={handleChange}
          />
        )}
      </form>
    </div>
  );
}

export default FiltersPanel;
