/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CheckSessionValidityResponse } from '../models/CheckSessionValidityResponse';
import type { VerifyGoogleAuthCodeRequest } from '../models/VerifyGoogleAuthCodeRequest';
import type { VerifyGoogleTokenRequest } from '../models/VerifyGoogleTokenRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class OauthGoogleService {

    /**
     * Verify Google Auth Code
     * Verifies a Google auth code and returns a session ID.
     *
     * Args:
     * request (Request): The incoming request.
     * response (Response): The outgoing response.
     * verify_google_auth_code_request (VerifyGoogleAuthCodeRequest): The request body.
     * app_bundle_id (str): The application bundle ID.
     * session_id (str, optional): The session ID. Defaults to None.
     *
     * Raises:
     * GoogleOAuthAccessTokenFetchError: If the token retrieval fails.
     * GoogleOAuthUserInfoFetchError: If the user information retrieval fails.
     *
     * Returns:
     * CheckSessionValidityResponse: The session validity response.
     * @param requestBody
     * @param sessionId
     * @returns CheckSessionValidityResponse Successful Response
     * @throws ApiError
     */
    public static verifyGoogleAuthCode(
        requestBody: VerifyGoogleAuthCodeRequest,
        sessionId?: (string | null),
    ): CancelablePromise<CheckSessionValidityResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/session/google/google_auth_code',
            cookies: {
                'session_id': sessionId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Verify Google Token
     * Verifies a Google token and returns a session ID.
     *
     * Args:
     * request (Request): The incoming request.
     * response (Response): The outgoing response.
     * verify_google_token_request (VerifyGoogleTokenRequest): The request body.
     * app_bundle_id (str): The application bundle ID.
     * session_id (str, optional): The session ID. Defaults to None.
     * @param requestBody
     * @param sessionId
     * @returns CheckSessionValidityResponse Successful Response
     * @throws ApiError
     */
    public static verifyGoogleToken(
        requestBody: VerifyGoogleTokenRequest,
        sessionId?: (string | null),
    ): CancelablePromise<CheckSessionValidityResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/session/google/google_login',
            cookies: {
                'session_id': sessionId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

}
