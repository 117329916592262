import React from "react";
import { OpenAPI } from "../openapi-typescript-axios";
import MainMapPage from "../pages/MainMapPage";
import SharedListNotYetImplementedPage, {
  sharedListLoader,
} from "../pages/SharedList";
import RouterErrorPage from "./RouterErrorPage";
import { ParcelInfoPopup, parcelPopupLoader } from "../pages/ParcelInfoPopup";

import * as Sentry from "@sentry/react";
import { createBrowserRouter, Navigate } from "react-router-dom";

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

// Because Router uses loader functions, these vars must be set here and not in
// index.tsx...
OpenAPI.BASE = process.env.REACT_APP_API_BASE_PATH ?? OpenAPI.BASE;
OpenAPI.WITH_CREDENTIALS = true;
OpenAPI.HEADERS = { "X-App-Bundle-Id": "com.housewren.wren" };

if (process.env.NODE_ENV === "development") {
  OpenAPI.HEADERS = {
    ...OpenAPI.HEADERS,
    "X-Custom-Dev-Server-CORS-Auth": "WrenDev",
  };
}

const router = sentryCreateBrowserRouter(
  [
    // Redirect /index.html to /
    {
      path: "/index.html",
      element: <Navigate to="/" replace />,
    },
    {
      // Used by Flutter.  If you change this, you must also change it in Pulumi
      // config, where Flutter gets it from (eventually).
      path: "/shared_list/:sharedListSlug",
      element: <SharedListNotYetImplementedPage />,
      loader: sharedListLoader,
    },
    {
      path: "",
      element: <MainMapPage showFiltersPanel={true} />,
      errorElement: <RouterErrorPage />,
      children: [
        {
          // Used by Flutter.  If you change this, you must also change it in
          // Pulumi config, where Flutter gets it from (eventually).
          path: "/listing/:parcelId",
          element: <ParcelInfoPopup />,
          loader: parcelPopupLoader,
        },
      ],
    },
    {
      path: "/list_results/",
      element: (
        <MainMapPage showListOfResults={true} showFiltersPanel={false} />
      ),
      errorElement: <RouterErrorPage />,
      children: [
        {
          // Used by Flutter.  If you change this, you must also change it in
          // Pulumi config, where Flutter gets it from (eventually).
          path: "/list_results/listing/:parcelId",
          element: <ParcelInfoPopup />,
          loader: parcelPopupLoader,
        },
      ],
    },
  ],
  {
    basename: process.env.REACT_APP_ROUTER_BASENAME ?? "/",
  },
);

export default router;
