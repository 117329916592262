import classNames from "classnames";
import React, { useState } from "react";
import "./WrenGoogleMap.css";
import { APIProvider, Map } from "@vis.gl/react-google-maps";
import { MapEvent } from "@vis.gl/react-google-maps/dist/components/map/use-map-events";
import { Outlet } from "react-router-dom";
import {
  useIsLoadingParcelQuery,
  useParcelQuery,
  useParcelQueryDispatch,
} from "../../contexts/ParcelQueryAndParcelQueryResponseProvider";
import useMarkers from "./AllMarkers";

/**
 * This function retrieves the zoom level from the local storage.
 * The zoom level is stored under the key "zoom".
 *
 * @returns {number | null} The zoom level as a number if it exists and is a valid number, otherwise null.
 */
function getZoomFromLocalStorage(): number | null {
  // Get the item from local storage with the key "zoom"
  const localStorageResult = localStorage.getItem("zoom");

  // If the result is not null or undefined
  if (localStorageResult) {
    // Parse the result to an integer
    const ret = parseInt(localStorageResult);

    // If the parsed result is a valid number (not NaN)
    if (!Number.isNaN(ret)) {
      // Return the parsed number
      return ret;
    }
  }

  // If the result is null, undefined, or NaN, return null
  return null;
}

function WrenGoogleMap({ isDarkMode }: { isDarkMode: boolean }) {
  const googleMapsAPIKey: string =
    process.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? "";

  const setParcelQuery = useParcelQueryDispatch();
  const parcelQuery = useParcelQuery();
  const isLoadingParcelQueryResults = useIsLoadingParcelQuery();

  // Save the initial parcel query, so we can use it to center the map.
  const [initialParcelQuery] = useState(parcelQuery);

  /**
   * This function updates the parcel query with the current map bounds.
   * It is called when the map camera changes (i.e., when the user moves the map or zooms in/out).
   *
   * @param {MapEvent} mapCameraChangedEvent - The event object passed by the map when the camera changes.
   */
  let updateParcelQueryWithMapBounds = (mapCameraChangedEvent: MapEvent) => {
    // Get the current map bounds
    let bounds = mapCameraChangedEvent.map.getBounds();

    // Log the bounds for debugging purposes
    console.debug("updateParcelQueryWithMapBounds called with bounds:", bounds);

    // If the bounds are null, return early
    if (bounds == null) {
      return;
    }

    // Store the bounds in a variable to ensure they don't change during the execution of the function
    const safeBounds = bounds;

    // Get the current zoom level of the map and convert it to a string
    let zoom_string = mapCameraChangedEvent.map?.getZoom()?.toString() ?? null;

    // If the zoom level is not null, store it in local storage
    if (zoom_string != null) {
      localStorage.setItem("zoom", zoom_string);
    }

    setParcelQuery({
      type: "update",
      payload: {
        ...parcelQuery,
        min_latitude: safeBounds.getSouthWest().lat(),
        max_latitude: safeBounds.getNorthEast().lat(),
        min_longitude: safeBounds.getSouthWest().lng(),
        max_longitude: safeBounds.getNorthEast().lng(),
      },
    });
  };

  return (
    // Important! Always set the container height explicitly
    // Set the width and height to inherit from parent
    <div
      className={"relative h-screen w-full"}
      style={{ width: "100%", height: "100%" }}
    >
      <APIProvider apiKey={googleMapsAPIKey}>
        <div
          className={classNames(
            "absolute z-10 h-2 w-full bg-gradient-to-r from-blue-500 to-blue-600 transition-opacity",
            {
              "duration-500": isLoadingParcelQueryResults, // 500ms for fade-in
              "duration-1000": !isLoadingParcelQueryResults, // 1000ms for fade-out
              "opacity-0": !isLoadingParcelQueryResults,
              "opacity-100": isLoadingParcelQueryResults,
            },
          )}
          style={{
            backgroundImage:
              "linear-gradient(to left, lightblue 0%, blue 50%, lightblue 100%, lightblue 150%)",
            backgroundSize: "600% 100%",
            animation: "gradient-move 2s linear infinite",
          }}
        />

        <Map
          mapId={"d81e12c8bcaeb4d0"}
          zoom={getZoomFromLocalStorage() ?? 7}
          center={{
            lat:
              (initialParcelQuery.min_latitude +
                initialParcelQuery.max_latitude) /
              2,
            lng:
              (initialParcelQuery.min_longitude +
                initialParcelQuery.max_longitude) /
              2,
          }}
          gestureHandling={"greedy"}
          disableDefaultUI={true}
          onIdle={updateParcelQueryWithMapBounds}
          clickableIcons={false}
        >
          {useMarkers({ isDarkMode })}
        </Map>
        <Outlet />
      </APIProvider>
    </div>
  );
}

export default React.memo(WrenGoogleMap);
