import React, { createContext, useReducer, useContext } from "react";

type FavoriteParcelsList = number[];

const FavoriteParcelsContext = createContext<FavoriteParcelsList | undefined>(
  undefined,
);
const FavoriteParcelsDispatchContext = createContext<
  React.Dispatch<any> | undefined
>(undefined);

type Action =
  | { type: "add"; payload: number }
  | { type: "remove"; payload: number }
  | { type: "set"; payload: FavoriteParcelsList };

function favoriteParcelsReducer(
  state: FavoriteParcelsList,
  action: Action,
): FavoriteParcelsList {
  switch (action.type) {
    case "add":
      state = [...state, action.payload];
      break;
    case "remove":
      state = state.filter((parcelId) => parcelId !== action.payload);
      break;
    case "set":
      state = action.payload;
      break;
    default:
      break;
  }
  console.log("favoriteParcelsReducer returning: ", state);
  return state;
}

export function FavoriteParcelsProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [favoriteParcels, dispatch] = useReducer(favoriteParcelsReducer, []);

  return (
    <FavoriteParcelsContext.Provider value={favoriteParcels}>
      <FavoriteParcelsDispatchContext.Provider value={dispatch}>
        {children}
      </FavoriteParcelsDispatchContext.Provider>
    </FavoriteParcelsContext.Provider>
  );
}

export function useFavoriteParcels() {
  const context = useContext(FavoriteParcelsContext);
  if (context === undefined) {
    throw new Error(
      "useFavoriteParcels must be used within a FavoriteParcelsProvider",
    );
  }
  return context;
}

export function useFavoriteParcelsDispatch() {
  const context = useContext(FavoriteParcelsDispatchContext);
  if (context === undefined) {
    throw new Error(
      "useFavoriteParcelsDispatch must be used within a FavoriteParcelsProvider",
    );
  }
  return context;
}
