/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum ParcelTypeEnum {
    CONDO = 'condo',
    SINGLE_FAMILY = 'single_family',
    LAND = 'land',
    MULTI_FAMILY = 'multi_family',
    MOBILE_HOME = 'mobile_home',
    FARM = 'farm',
    COMMERCIAL = 'commercial',
}
