import TimeAgo from "javascript-time-ago";

import en from "javascript-time-ago/locale/en";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ReactTimeAgo from "react-time-ago";
import {
  useParcelQueryRequestIsFetchingForListOfResults,
  useParcelQueryResponse,
} from "../../contexts/ParcelQueryAndParcelQueryResponseProvider";
import { Parcel, ParcelPicture } from "../../openapi-typescript-axios";
import FavoriteButton from "../favorite_button/FavoriteButton";

TimeAgo.addDefaultLocale(en);

export function ListOfParcelResults() {
  const currencyFormat: Intl.NumberFormat = Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const parcelQueryResponse = useParcelQueryResponse();
  const parcelResults = parcelQueryResponse?.paginated_parcels;
  const setParcelQueryIsFetchingForListOfResults =
    useParcelQueryRequestIsFetchingForListOfResults();
  const navigate = useNavigate();

  if (parcelResults !== undefined) {
    debugger;
  }

  // When this mounts, it should ask for extra columns, and undo that ask when it unmounts.
  useEffect(() => {
    setParcelQueryIsFetchingForListOfResults(true);

    return () => {
      setParcelQueryIsFetchingForListOfResults(false);
    };
  }, [setParcelQueryIsFetchingForListOfResults]);

  // When this unmounts, it should call parcelQuery.setWoof()

  function ParcelResult({ parcel }: { parcel: Parcel }) {
    let firstPicture: ParcelPicture | null | undefined = parcel.pictures
      ? parcel.pictures[0]
      : undefined;
    // debugger;

    let originalEntryTimestampUtc =
      parcel.original_entry_timestamp_utc != null
        ? Date.parse(parcel.original_entry_timestamp_utc)
        : null;

    let numberOfDaysSinceOriginalEntry =
      originalEntryTimestampUtc != null
        ? Math.floor(
            (Date.now() - originalEntryTimestampUtc) / (1000 * 60 * 60 * 24),
          )
        : null;

    return (
      <div className="mx-4 flex h-fit flex-col rounded-lg bg-white shadow-[0_0_20px_#ddd]">
        <div className="relative h-fit w-full rounded-t-md object-cover">
          <div className={"absolute right-2 top-2 z-10"}>
            <FavoriteButton parcelId={3} key={"favoritebutton"} />
          </div>

          <img
            src={
              (parcelQueryResponse?.pictures_root ?? "") +
              (firstPicture?.blob_name ?? "")
            }
            className="cursor-pointer"
            alt={"woof"}
          />

          {originalEntryTimestampUtc != null &&
          numberOfDaysSinceOriginalEntry! < 7 ? (
            <div className="h-1/4th border-gray-3000 absolute left-2 top-2 z-10 w-fit min-w-[100px] rounded-lg rounded-xl border bg-purple-500 px-3 py-2 text-sm font-bold text-white">
              <ReactTimeAgo date={originalEntryTimestampUtc} locale="en-US" />
            </div>
          ) : (
            ""
          )}
        </div>

        <div className="relative px-1 pt-2">
          <p className="cursor-pointer text-sm text-gray-500">
            {parcel.address}
          </p>
          <div className="cursor-pointerr mb-2 inline-block">
            {parcel.beds} beds,
            {(parcel.full_baths ?? 0) + (parcel.half_baths ?? 0)} baths
          </div>
          <div className="float-right mb-2 cursor-pointer text-xl font-bold">
            {currencyFormat.format(parcel.list_price)}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="h-screen w-96 overflow-y-scroll py-2">
      <div>
        <button
          className={
            "rounded-md bg-green-500 px-4 py-2 font-semibold text-white transition duration-300 ease-in-out hover:bg-green-600"
          }
          onClick={(_) => {
            navigate("/");
          }}
        >
          Filters
        </button>
      </div>

      <div className="px-2 py-6 text-xl font-bold">300 results:</div>
      {/*Create one for each parcel.*/}
      {parcelResults !== undefined
        ? parcelResults?.map((parcel) => (
            <div
              key={parcel.id}
              onClick={(_) => navigate("/listing/" + parcel.id)}
            >
              <ParcelResult parcel={parcel} />
            </div>
          ))
        : ""}
    </div>
  );
}
