import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart as solidHeart } from "@fortawesome/free-solid-svg-icons";
import { faHeart } from "@fortawesome/free-regular-svg-icons";
import "./FavoriteButton.css";
import classNames from "classnames";
import { ApiError, FavoritesService } from "../../openapi-typescript-axios";
import { enqueueSnackbar } from "notistack";
import {
  useFavoriteParcels,
  useFavoriteParcelsDispatch,
} from "../../contexts/FavoriteParcelsContext";

const UNAUTHORIZED_STATUS_RESPONSE_CODE = 401;

function FavoriteButton({ parcelId }: { parcelId: number }) {
  const modifyFavoriteParcels = useFavoriteParcelsDispatch();
  const favoriteParcels = useFavoriteParcels();

  const isFavorite = favoriteParcels.includes(parcelId);

  // Store a variable that indicates where the user has clicked on this button
  // so that we can animate the button when they click on it.
  const [didClickOnFavoriteButton, setDidClickOnFavoriteButton] =
    useState(false);

  async function onClick() {
    console.log("Setting isFavorite from ", isFavorite, " to ", !isFavorite);

    setDidClickOnFavoriteButton(true);

    try {
      const response = isFavorite
        ? await FavoritesService.removeFavorite(parcelId)
        : await FavoritesService.addFavorite(parcelId);
      console.info(
        isFavorite ? "deleteFavorite response: " : "addFavorite response: ",
        response,
      );

      modifyFavoriteParcels({
        type: isFavorite ? "remove" : "add",
        payload: parcelId,
      });
    } catch (e) {
      console.error(e);
      if (e instanceof ApiError) {
        if (e.status === UNAUTHORIZED_STATUS_RESPONSE_CODE) {
          // TODO(tek0): Call up to a higher level function to show the login popup
        }

        const whatWentWrong = e.status + " - " + e.message;
        enqueueSnackbar(
          isFavorite
            ? "Error removing favorite: " + whatWentWrong
            : "Error adding favorite: " + whatWentWrong,
        );
      }
    }
  }

  return (
    <button className="favorite-button cursor-pointer" onClick={onClick}>
      <div style={{ position: "relative" }}>
        <FontAwesomeIcon
          icon={faHeart}
          className={classNames("text-3xl ", {
            "text-black": true,
          })}
          style={{ position: "absolute" }}
        />
        {/* If the user has clicked on the favorite button, animate the button */}
        {didClickOnFavoriteButton && (
          <FontAwesomeIcon
            icon={isFavorite ? solidHeart : faHeart}
            className={classNames("text-3xl ", {
              "animate-ping-once": isFavorite,
              "text-red-500": isFavorite,
            })}
            style={{ position: "absolute" }}
          />
        )}
        <FontAwesomeIcon
          icon={isFavorite ? solidHeart : faHeart}
          className={classNames("text-3xl ", {
            "text-red-500": isFavorite,
          })}
        />
      </div>
    </button>
  );
}

export default FavoriteButton;
