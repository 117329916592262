import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext } from "react";
import { ParcelTypeEnum } from "../../openapi-typescript-axios";
import {
  faBuilding,
  faCaravan,
  faCity,
  faHome,
  faLeaf,
  faStore,
  faTractor,
  IconDefinition,
} from "@fortawesome/free-solid-svg-icons";
import {
  ParcelQueryContext,
  ParcelQueryDispatchContext,
} from "../../contexts/ParcelQueryAndParcelQueryResponseProvider";

const parcelTypesAndIcons: Record<ParcelTypeEnum, IconDefinition> = {
  [ParcelTypeEnum.SINGLE_FAMILY]: faBuilding,
  [ParcelTypeEnum.CONDO]: faHome,
  [ParcelTypeEnum.LAND]: faLeaf,
  [ParcelTypeEnum.MULTI_FAMILY]: faCity,
  [ParcelTypeEnum.MOBILE_HOME]: faCaravan,
  [ParcelTypeEnum.FARM]: faTractor,
  [ParcelTypeEnum.COMMERCIAL]: faStore,
  // Add the rest of the ParcelTypeEnum values and their corresponding icons here
};

const parcelTypesAndLabels: Record<ParcelTypeEnum, string> = {
  [ParcelTypeEnum.SINGLE_FAMILY]: "Single Family",
  [ParcelTypeEnum.CONDO]: "Condo",
  [ParcelTypeEnum.LAND]: "Land",
  [ParcelTypeEnum.MULTI_FAMILY]: "Multi-Family",
  [ParcelTypeEnum.MOBILE_HOME]: "Mobile Home",
  [ParcelTypeEnum.FARM]: "Farm",
  [ParcelTypeEnum.COMMERCIAL]: "Commercial",
  // Add the rest of the ParcelTypeEnum values and their corresponding icons here
};

function ParcelTypesSelector() {
  const parcelQuery = useContext(ParcelQueryContext);
  const setParcelQuery = useContext(ParcelQueryDispatchContext);

  function toggleItem(array: ParcelTypeEnum[], item: ParcelTypeEnum) {
    if (array.includes(item)) {
      return array.filter((i) => i !== item);
    } else {
      return array.concat(item);
    }
  }

  function ParcelTypeSelectorButton({
    parcelType,
  }: {
    parcelType: ParcelTypeEnum;
  }) {
    return (
      <button
        className={
          "flex aspect-square flex-col items-center " +
          "justify-center border px-1 " +
          `${
            parcelQuery.parcel_types?.includes(parcelType)
              ? "bg-blue-300 text-blue-900"
              : "border-black"
          } ` +
          "hover:cursor-pointer hover:border-blue-300 border-black"
        }
        key={parcelType}
        onClick={(event) => {
          setParcelQuery({
            type: "update",
            payload: {
              ...parcelQuery,
              parcel_types: toggleItem(
                parcelQuery.parcel_types ?? [],
                parcelType
              ),
            },
          });
          event.preventDefault();
        }}
      >
        <FontAwesomeIcon
          icon={parcelTypesAndIcons[parcelType]}
          className="mb-1 text-xl"
        />

        <span className={"select-none text-center text-xs"}>
          {parcelTypesAndLabels[parcelType]}
        </span>
      </button>
    );
  }

  return (
    <div className="py-2">
      <h2 className="mb-4 font-semibold">Type of Unit:</h2>
      <div className={"te flex flex-col items-center justify-center text-sm"}>
        <div className="grid grid-cols-4 px-4">
          {Object.keys(parcelTypesAndIcons)
            .slice(0, 4)
            .map((key) => {
              return (
                <ParcelTypeSelectorButton
                  parcelType={
                    ParcelTypeEnum[
                      key.toUpperCase() as keyof typeof ParcelTypeEnum
                    ]
                  }
                  key={key}
                />
              );
            })}
        </div>
        <div className="grid grid-cols-3 px-4 ">
          {/*Make out of parcelTypesAndIcons*/}
          {Object.keys(parcelTypesAndIcons)
            .slice(4)
            .map((key) => {
              return (
                <ParcelTypeSelectorButton
                  parcelType={
                    ParcelTypeEnum[
                      key.toUpperCase() as keyof typeof ParcelTypeEnum
                    ]
                  }
                  key={key}
                />
              );
            })}
        </div>
      </div>
    </div>
  );
}

export default ParcelTypesSelector;
