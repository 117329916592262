/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddParcelToListResponse } from '../models/AddParcelToListResponse';
import type { Parcel } from '../models/Parcel';
import type { ParcelListInfo } from '../models/ParcelListInfo';
import type { ParcelListShareInfo } from '../models/ParcelListShareInfo';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ParcelListsService {

    /**
     * Get Lists
     * Returns the ParcelLists belonging to logged-in user.
     *
     * Args:
     * user (SQLRegisteredUser): current logged-in user, supplied by Dependency
     * Injection.
     * app_bundle_id: The bundle ID of the app requesting the lists.
     *
     * Returns:
     * list[SQLParcelList]: List of SQLParcelList objects.
     * @param sessionId
     * @returns ParcelListInfo Successful Response
     * @throws ApiError
     */
    public static getLists(
        sessionId?: (string | null),
    ): CancelablePromise<Array<ParcelListInfo>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/lists/',
            cookies: {
                'session_id': sessionId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get List Parcels
     * Returns the contents of a SQLParcelList.
     *
     * Args:
     * list_id (int): id of the list desired.
     * slug (str): slug of the list desired.
     * user (SQLRegisteredUser): logged-in user (dependency-injected).
     *
     * Returns:
     * list[SQLParcel]: list of parcel objs from SQL.
     * @param listId
     * @param slug
     * @param sessionId
     * @returns Parcel Returns list of Parcels inside list owned by user.
     * @throws ApiError
     */
    public static getListParcels(
        listId: number,
        slug?: (string | null),
        sessionId?: (string | null),
    ): CancelablePromise<Array<Parcel>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/lists/contents',
            cookies: {
                'session_id': sessionId,
            },
            query: {
                'list_id': listId,
                'slug': slug,
            },
            errors: {
                404: `Error when list not found (or not shared with user).`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create List
     * Creates a new ParcelList for user.
     *
     * Args:
     * name (str): Name of the list.
     * user (SQLRegisteredUser): Logged-in User.
     * app_bundle_id (str): The bundle ID of the app requesting the lists.
     *
     * Returns:
     * ParcelListInfo: info obj for newly created list.
     * @param name
     * @param sessionId
     * @returns ParcelListInfo Returns info about new ParcelList (but not its contents)
     * @throws ApiError
     */
    public static createList(
        name: string,
        sessionId?: (string | null),
    ): CancelablePromise<ParcelListInfo> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/lists/create',
            cookies: {
                'session_id': sessionId,
            },
            query: {
                'name': name,
            },
            errors: {
                409: `When a list with the given name already exists for logged-in user.`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete List
     * Deletes an existing SQLParcelList.
     *
     * Args:
     * list_id (int):
     * user (SQLRegisteredUser):
     *
     * Returns:
     * @param listId
     * @param sessionId
     * @returns boolean Boolean value denoting operation success
     * @throws ApiError
     */
    public static deleteList(
        listId: number,
        sessionId?: (string | null),
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/lists/delete',
            cookies: {
                'session_id': sessionId,
            },
            query: {
                'list_id': listId,
            },
            errors: {
                404: `When we can't find list with given id for user`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Add Parcel To List
     * Adds a parcel to a list.
     *
     * Args:
     * response (Response): Response object.
     * parcel_id (int): id of parcel to add.
     * list_id (int): id of list to add parcel to.
     * user (SQLRegisteredUser): logged-in user (dependency-injected).
     *
     * Returns:
     * AddParcelToListResponse: response obj.
     * @param parcelId
     * @param listId
     * @param sessionId
     * @returns AddParcelToListResponse Successful Response
     * @throws ApiError
     */
    public static addParcelToList(
        parcelId: number,
        listId: number,
        sessionId?: (string | null),
    ): CancelablePromise<AddParcelToListResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/lists/add_parcel',
            cookies: {
                'session_id': sessionId,
            },
            query: {
                'parcel_id': parcelId,
                'list_id': listId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Remove Parcel From List
     * Removes a parcel from a list.
     *
     * Args:
     * parcel_id (int): id of parcel to remove
     * list_id (int): id of list to remove parcel from
     * user (SQLRegisteredUser): logged-in user
     * @param parcelId
     * @param listId
     * @param sessionId
     * @returns boolean Successful Response
     * @throws ApiError
     */
    public static removeParcelFromList(
        parcelId: number,
        listId: number,
        sessionId?: (string | null),
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/lists/remove_parcel',
            cookies: {
                'session_id': sessionId,
            },
            query: {
                'parcel_id': parcelId,
                'list_id': listId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Move List
     * Moves a list to a new index.
     *
     * Args:
     * list_id (int): id of list to move
     * new_index (int): new index to move list to
     * user (SQLRegisteredUser): logged-in user
     *
     * Returns:
     * bool: True if successful
     * @param listId
     * @param newIndex
     * @param sessionId
     * @returns boolean Successful Response
     * @throws ApiError
     */
    public static moveList(
        listId: number,
        newIndex: number,
        sessionId?: (string | null),
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/lists/reorder_list',
            cookies: {
                'session_id': sessionId,
            },
            query: {
                'list_id': listId,
                'new_index': newIndex,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Move Parcel
     * Moves a parcel from one position to another in a list.
     *
     * Args:
     * list_id (int): id of list to move parcel in
     * old_index (int): old index of parcel
     * new_index (int): new index of parcel
     * user (SQLRegisteredUser): logged-in user
     * @param listId
     * @param oldIndex
     * @param newIndex
     * @param sessionId
     * @returns boolean Successful Response
     * @throws ApiError
     */
    public static moveParcel(
        listId: number,
        oldIndex: number,
        newIndex: number,
        sessionId?: (string | null),
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/lists/reorder_parcel',
            cookies: {
                'session_id': sessionId,
            },
            query: {
                'list_id': listId,
                'old_index': oldIndex,
                'new_index': newIndex,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Shared List Info
     * Returns information about a shared list.
     *
     * Args:
     * slug (str): slug (short identifier) of list to get info about
     * user (SQLRegisteredUser): logged-in user
     *
     * Returns:
     * ParcelListInfo | None: info about list.
     * @param slug
     * @param sessionId
     * @returns ParcelListInfo Successful Response
     * @throws ApiError
     */
    public static getSharedListInfo(
        slug: string,
        sessionId?: (string | null),
    ): CancelablePromise<ParcelListInfo> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/lists/shared_list',
            cookies: {
                'session_id': sessionId,
            },
            query: {
                'slug': slug,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Unshare List
     * Unshares a list.
     *
     * Args:
     * list_id (int): id of list to unshare
     * user (SQLRegisteredUser): logged-in user
     *
     * Returns:
     * bool: True if successful
     * @param listId
     * @param sessionId
     * @returns boolean Successful Response
     * @throws ApiError
     */
    public static unshareList(
        listId: number,
        sessionId?: (string | null),
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/lists/unshare',
            cookies: {
                'session_id': sessionId,
            },
            query: {
                'list_id': listId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Share List
     * Shares a list (creates a slug for list if need be and returns info).
     *
     * Args:
     * request (Request): request object
     * list_id (int): id of list to share
     * user (SQLRegisteredUser): logged-in user
     *
     * Returns:
     * @param listId
     * @param sessionId
     * @returns ParcelListShareInfo Successful Response
     * @throws ApiError
     */
    public static shareList(
        listId: number,
        sessionId?: (string | null),
    ): CancelablePromise<ParcelListShareInfo> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/lists/share',
            cookies: {
                'session_id': sessionId,
            },
            query: {
                'list_id': listId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Number Of Lists Parcel Is In
     * Returns the number of lists a parcel is in.
     *
     * Args:
     * parcel_id (int): id of parcel to check
     * user (SQLRegisteredUser): logged-in user
     *
     * Returns:
     * int: number of lists parcel is in
     * @param parcelId
     * @param sessionId
     * @returns number Successful Response
     * @throws ApiError
     */
    public static getNumberOfListsParcelIsIn(
        parcelId: number,
        sessionId?: (string | null),
    ): CancelablePromise<number> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/lists/parcel_in_number_of_lists',
            cookies: {
                'session_id': sessionId,
            },
            query: {
                'parcel_id': parcelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
