// SharedListNotYetImplementedPage definition

import { useEffect } from "react";
import { useLoaderData } from "react-router-dom";

export const sharedListLoader = async ({
  params,
}: {
  params: { sharedListSlug: string };
}) => {
  return { sharedListSlug: params.sharedListSlug };
};

export default function SharedListNotYetImplementedPage() {
  const { sharedListSlug } = useLoaderData() as {
    sharedListSlug: string;
  };
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  useEffect(() => {
    // Detect if we're on a mobile device
    if (isMobile) {
      // Attempt to open the app
      window.location.href = `housewren://list/${sharedListSlug}`;
    }
  }, [sharedListSlug, isMobile]);

  return (
    <div>
      <div className={"text-xl font-bold"}>Shared List</div>
      <p>
        This feature is not yet implemented for web. Please check back later. If
        you are on mobile and have the Housewren app installed, the app should
        open automatically...
      </p>
    </div>
  );
}
