import React, { createContext, useContext, useState } from "react";
import { RegisteredUser } from "../openapi-typescript-axios";

const CurrentLoggedInUserContext = createContext<RegisteredUser | null>(null);
const CurrentLoggedInUserDispatchContext = createContext<
  React.Dispatch<any> | undefined
>(undefined);

export function CurrentLoggedInUserProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [currentLoggedInUser, setCurrentLoggedInUser] =
    useState<RegisteredUser | null>(null);

  return (
    <CurrentLoggedInUserContext.Provider value={currentLoggedInUser}>
      <CurrentLoggedInUserDispatchContext.Provider
        value={setCurrentLoggedInUser}
      >
        {children}
      </CurrentLoggedInUserDispatchContext.Provider>
    </CurrentLoggedInUserContext.Provider>
  );
}

export function useCurrentLoggedInUser() {
  const context = useContext(CurrentLoggedInUserContext);
  if (context === undefined) {
    throw new Error(
      "useCurrentLoggedInUser must be used within a CurrentLoggedInUserProvider",
    );
  }
  return context;
}

export function useSetCurrentLoggedInUser() {
  const context = useContext(CurrentLoggedInUserDispatchContext);
  if (context === undefined) {
    throw new Error(
      "useCurrentLoggedInUserDispatch must be used within a CurrentLoggedInUserDispatchProvider",
    );
  }
  return context;
}
