import { useRouteError } from "react-router-dom";

export default function RouterErrorPage() {
  const error = useRouteError();
  console.error(error);

  return (
    <div>
      <h1>Error:</h1>
      {Object.entries(error).map(([key, value], index) => (
        <p key={index}>{`${key}: ${JSON.stringify(value)}`}</p>
      ))}
    </div>
  );
}
