/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CheckSessionValidityResponse } from '../models/CheckSessionValidityResponse';
import type { PushNotificationTokenWithPlatformType } from '../models/PushNotificationTokenWithPlatformType';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SessionService {

    /**
     * Logout User
     * Logs out a user.
     *
     * If the user is a known-email RegisteredUser, they are degraded to an anonymous
     * account, either the one that they last used (unless it was upgraded to a known-email
     * account), or to a new anonymous account.
     *
     * Returns:
     * CheckSessionValidityResponse: updated session information.
     * @param sessionId
     * @param requestBody
     * @returns CheckSessionValidityResponse Successful Response
     * @throws ApiError
     */
    public static logoutUser(
        sessionId?: (string | null),
        requestBody?: (PushNotificationTokenWithPlatformType | null),
    ): CancelablePromise<CheckSessionValidityResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/session/logout',
            cookies: {
                'session_id': sessionId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Account
     * Deletes a user's account.
     *
     * Returns:
     * CheckSessionValidityResponse: updated session information.
     * @param sessionId
     * @returns CheckSessionValidityResponse Successful Response
     * @throws ApiError
     */
    public static deleteAccount(
        sessionId?: (string | null),
    ): CancelablePromise<CheckSessionValidityResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/session/delete_account',
            cookies: {
                'session_id': sessionId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Refresh Session
     * Refreshes a user's session.
     *
     * Args:
     * push_notification_token_with_platform_type (PushNotificationTokenWithPlatformType | None):
     * The push notification token for the user's device.
     * session_id (str): The session ID.
     * user (SQLRegisteredUser): The user.
     * app_bundle_id (str): The bundle ID of the app that this session is associated
     * with.
     *
     * Returns:
     * CheckSessionValidityResponse: updated session information.
     * @param sessionId
     * @param requestBody
     * @returns CheckSessionValidityResponse Successful Response
     * @throws ApiError
     */
    public static refreshSession(
        sessionId?: (string | null),
        requestBody?: (PushNotificationTokenWithPlatformType | null),
    ): CancelablePromise<CheckSessionValidityResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/session/refresh',
            cookies: {
                'session_id': sessionId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

}
