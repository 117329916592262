/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class FavoritesService {

    /**
     * Add Favorite
     * Adds a parcel to a User's favorites.
     *
     * Args:
     * parcel_id: The ID of the parcel to add to the User's favorites.
     * user: RegisteredUser object of current session.
     * app_bundle_id: The bundle ID of the app that this favorite is associated with.
     *
     * Returns:
     * True if the parcel was added to the User's favorites.
     *
     * Raises:
     * ParcelDoesNotExist: If the parcel is not found.
     * IntegrityError: If the parcel is already a favorite.
     * @param parcelId
     * @param sessionId
     * @returns boolean Successful Response
     * @throws ApiError
     */
    public static addFavorite(
        parcelId: number,
        sessionId?: (string | null),
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/favorites/add',
            cookies: {
                'session_id': sessionId,
            },
            query: {
                'parcel_id': parcelId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Remove Favorite
     * Unmark a parcel as a favorite
     *
     * Args:
     * app_bundle_id: The bundle ID of the app that this favorite is associated with.
     * parcel_id: The ID of the parcel to remove from the User's favorites.
     * user: RegisteredUser object of current session.
     *
     * Raises:
     * ParcelDoesNotExist: If the parcel is not found.
     *
     * Returns:
     * True if the parcel was removed from the User's favorites, False otherwise.
     * @param requestBody
     * @param sessionId
     * @returns boolean Successful Response
     * @throws ApiError
     */
    public static removeFavorite(
        requestBody: number,
        sessionId?: (string | null),
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/favorites/remove',
            cookies: {
                'session_id': sessionId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

}
