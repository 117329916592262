/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ConnectWithRealtorResponse } from '../models/ConnectWithRealtorResponse';
import type { GetTrainStationsRequest } from '../models/GetTrainStationsRequest';
import type { ParcelQueryRequest } from '../models/ParcelQueryRequest';
import type { ParcelQueryResponse } from '../models/ParcelQueryResponse';
import type { SendIntroEmailRequest } from '../models/SendIntroEmailRequest';
import type { TrainStation } from '../models/TrainStation';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ParcelsService {

    /**
     * Get Parcel
     * Args:
     * parcel_id: The ID of the parcel to get.
     * app_bundle_id: The bundle ID of the app that this favorite is associated with.
     * client_mobile_app_version_string: The version string of the mobile client's app.
     * client_mobile_app_build_number: The build number of the mobile client's app.
     * user: RegisteredUser object of current session.
     *
     * Returns:
     * @param parcelId
     * @param sessionId
     * @returns ParcelQueryResponse Successful Response
     * @throws ApiError
     */
    public static getParcel(
        parcelId: number,
        sessionId?: (string | null),
    ): CancelablePromise<ParcelQueryResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/parcels/get',
            cookies: {
                'session_id': sessionId,
            },
            query: {
                'parcel_id': parcelId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Parcels
     * Looks up one or more parcels by query.
     *
     * Args:
     * request (Request): starlette Request object. Used for non-fatal error reporting.
     * parcel_query_request (ParcelQueryRequest):
     * user: SQLRegisteredUser - not required, unless doing a favorites search.
     * app_bundle_id: The bundle ID of the app that this favorite is associated with.
     *
     * Returns:
     * ParcelQueryResponse
     * @param requestBody
     * @param profile
     * @param sessionId
     * @returns ParcelQueryResponse Successful Response
     * @throws ApiError
     */
    public static getParcels(
        requestBody: ParcelQueryRequest,
        profile: boolean = false,
        sessionId?: (string | null),
    ): CancelablePromise<ParcelQueryResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/parcels/',
            cookies: {
                'session_id': sessionId,
            },
            query: {
                'profile': profile,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Train Stations
     * Gets a list of train stations within coordinates.
     *
     * Args:
     * request (GetTrainStationsRequest):
     *
     * Returns:
     * List of TrainStation objects
     * @param requestBody
     * @returns TrainStation Successful Response
     * @throws ApiError
     */
    public static getTrainStations(
        requestBody: GetTrainStationsRequest,
    ): CancelablePromise<Array<TrainStation>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/parcels/maps/trains/stations',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Connect With Realtor
     * Connects User with an agent that has chat enabled.
     *
     * Args:
     * parcel_id (int):
     *
     * Returns:
     * ConnectWithRealtorResponse
     * @param parcelId
     * @returns ConnectWithRealtorResponse Successful Response
     * @throws ApiError
     */
    public static connectWithRealtor(
        parcelId: number,
    ): CancelablePromise<ConnectWithRealtorResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/parcels/connect_with_realtor',
            query: {
                'parcel_id': parcelId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Send Intro Email
     * Sends an intro email between Agent(s) and a buyer.
     *
     * Args:
     * request:
     *
     * Returns:
     * @param requestBody
     * @returns string Successful Response
     * @throws ApiError
     */
    public static sendIntroEmail(
        requestBody: SendIntroEmailRequest,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/parcels/send_intro_email',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

}
