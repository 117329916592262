import "./index.css";
import { createTheme, MantineProvider } from "@mantine/core";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { SnackbarProvider } from "notistack";
import React from "react";
import { createRoot } from "react-dom/client";
import { Helmet } from "react-helmet";
import { Provider } from "react-redux";
import { RouterProvider } from "react-router-dom";
import { store } from "./app/store";
import { CurrentLoggedInUserProvider } from "./contexts/CurrentLoggedInUser";
import { FavoriteParcelsProvider } from "./contexts/FavoriteParcelsContext";
import ParcelQueryAndParcelQueryResponseProvider from "./contexts/ParcelQueryAndParcelQueryResponseProvider";
import router from "./router/MainRouter";
import initSentry from "./sentry/sentry";

import "@mantine/core/styles.css";

if (process.env.NODE_ENV === "production") {
  console.log = console.error = console.debug = () => {};
}

const theme = createTheme({
  /** Put your mantine theme override here */
});

const WrenApp = () => (
  <MantineProvider theme={theme}>
    <Helmet></Helmet>
    <GoogleOAuthProvider clientId="490266862013-qlupk5sjoaf6hftuiv7hj4n1t3eq711v.apps.googleusercontent.com">
      <React.StrictMode>
        <Provider store={store}>
          <CurrentLoggedInUserProvider>
            <FavoriteParcelsProvider>
              <ParcelQueryAndParcelQueryResponseProvider>
                <SnackbarProvider>
                  <RouterProvider router={router} />
                </SnackbarProvider>
              </ParcelQueryAndParcelQueryResponseProvider>
            </FavoriteParcelsProvider>
          </CurrentLoggedInUserProvider>
        </Provider>
      </React.StrictMode>
    </GoogleOAuthProvider>
  </MantineProvider>
);

initSentry();
createRoot(document.getElementById("root")!).render(<WrenApp />);
