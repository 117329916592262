import { useMap } from "@vis.gl/react-google-maps";
import { formatPriceForMapMarkers } from "../../../utils/PriceFormatters";
import WrenBaseMarker from "./WrenBaseMarker";
import { useNavigate } from "react-router-dom";
import { ParcelCluster } from "../../../openapi-typescript-axios";

export function ClusterMarker({
  parcelCluster,
  isDarkMode,
  isRentals = false,
}: {
  parcelCluster: ParcelCluster;
  isDarkMode: boolean;
  isRentals?: boolean;
}) {
  const firstParcelId = parcelCluster.first_parcel_id;
  const lat = parcelCluster.latitude;
  const lng = parcelCluster.longitude;
  const minPrice = parcelCluster.min_price;
  const maxPrice = parcelCluster.max_price;
  const minLatitude = parcelCluster.min_latitude;
  const maxLatitude = parcelCluster.max_latitude;
  const minLongitude = parcelCluster.min_longitude;
  const maxLongitude = parcelCluster.max_longitude;
  const numberOfParcels = parcelCluster.parcel_count;

  const containsFavorite = parcelCluster.contains_favorite;
  const numberOfFavoritesContained =
    parcelCluster.number_of_favorites_contained;

  // Move the map to the bounds of this cluster
  const map = useMap();
  const navigate = useNavigate();

  function handleClickOnMarker(e: google.maps.MapMouseEvent) {
    if (numberOfParcels === 1) {
      navigate("/listing/" + firstParcelId);
    }
    map?.fitBounds(
      {
        north: maxLatitude,
        south: minLatitude,
        east: maxLongitude,
        west: minLongitude,
      },
      10,
    );
  }

  const text =
    numberOfParcels > 1
      ? formatPriceForMapMarkers(minPrice) +
        " - " +
        formatPriceForMapMarkers(maxPrice)
      : formatPriceForMapMarkers(minPrice);

  return WrenBaseMarker({
    lat,
    lng,
    text,
    isDarkMode: isDarkMode,
    isRental: isRentals,
    containsFavorite: containsFavorite,
    numberOfFavoritesContained: numberOfFavoritesContained,
    handleClickOnMarker: handleClickOnMarker,
  });
}
