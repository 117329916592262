import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/react";

const sentryReduxEnhancer = Sentry.createReduxEnhancer({});

export const store = configureStore({
  reducer: (state, action) => {
    return state;
  },
  enhancers: (defaultEnhancers) => {
    return defaultEnhancers.concat(sentryReduxEnhancer);
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
