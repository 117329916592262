import { faAnglesLeft, faAnglesRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import React, { useState } from "react";
import FiltersPanel from "../components/filters/FiltersPanel";
import { ListOfParcelResults } from "../components/list_of_results/ListOfParcelResults";
import WrenGoogleMap from "../components/maps/WrenGoogleMap";
import MainLayout from "../layouts/MainLayout";

function MainMapPage({
  showFiltersPanel = true,
  showListOfResults = false,
}: Readonly<{ showFiltersPanel?: boolean; showListOfResults?: boolean }>) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  function toggleFiltersHandler() {
    console.log("toggleFiltersHandler called");
    setIsModalOpen(!isModalOpen);
  }

  const isDarkMode = true;

  if (isDarkMode) {
    console.warn("Dark mode is not supported yet.");
  }

  return (
    <MainLayout>
      <div
        className={
          isModalOpen
            ? "hidden md:visible md:flex md:basis-3/5"
            : "grow md:basis-3/5"
        }
      >
        <WrenGoogleMap isDarkMode={isDarkMode} />
      </div>
      <button
        className="absolute right-0 md:hidden"
        onClick={() => toggleFiltersHandler()}
      >
        <FontAwesomeIcon
          icon={isModalOpen ? faAnglesRight : faAnglesLeft}
          className={
            "rounded-lg border-2 border-white p-1 text-xl " +
            "bg-green-600 hover:cursor-pointer  hover:border-amber-300"
          }
          onClick={() => {
            toggleFiltersHandler();
          }}
        />
      </button>
      <div
        className={
          (isModalOpen ? "flex grow align-middle" : "hidden md:w-2/5") +
          "text-auto md:flex"
        }
      >
        <div className={classNames("", { hidden: !showFiltersPanel })}>
          <FiltersPanel key={"2"} />
        </div>

        <div className={classNames("", { hidden: !showListOfResults })}>
          <ListOfParcelResults />
        </div>
      </div>
    </MainLayout>
  );
}

export default MainMapPage;
