const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

const formatThousands = (value: number): string => {
  const isInteger = (value / 1000) % 1 === 0;
  return (
    new Intl.NumberFormat("en-US", {
      style: "decimal",
      minimumFractionDigits: isInteger ? 0 : 1,
      maximumFractionDigits: isInteger ? 0 : 1,
    }).format(value / 1000) + "K"
  );
};

const formatMillions = (value: number): string => {
  const isInteger = (value / 1000000) % 1 === 0;
  return (
    new Intl.NumberFormat("en-US", {
      style: "decimal",
      minimumFractionDigits: isInteger ? 0 : 2,
      maximumFractionDigits: isInteger ? 0 : 2,
    }).format(value / 1000000) + "M"
  );
};

const formatBillions = (value: number): string => {
  const isInteger = (value / 1000000000) % 1 === 0;
  return (
    new Intl.NumberFormat("en-US", {
      style: "decimal",
      minimumFractionDigits: isInteger ? 0 : 2,
      maximumFractionDigits: isInteger ? 0 : 2,
    }).format(value / 1000000000) + "B"
  );
};

export function formatPriceForMapMarkers(
  price: number,
  isRental?: boolean | null,
): string {
  if (isRental) {
    return currencyFormatter.format(price);
  }

  if (price < 1000) return `$${price.toFixed(0)}`;
  if (price < 100000) {
    return `$${formatThousands(price)}`;
  } else if (price < 950000) {
    return `$${formatThousands(Math.round(price / 1000) * 1000)}`;
  } else if (price < 999950000) {
    return `$${formatMillions(price)}`;
  }
  return `$${formatBillions(price)}`;
}

export function formatPrice(price: number) {
  return currencyFormatter.format(price);
}
