import { AdvancedMarker } from "@vis.gl/react-google-maps";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart as solidHeart } from "@fortawesome/free-solid-svg-icons";
import { faHeart as outlineHeart } from "@fortawesome/free-regular-svg-icons";
import classNames from "classnames";

export default function WrenBaseMarker({
  lat,
  lng,
  text,
  isRental = false,
  isFavorite = false,
  containsFavorite = false,
  numberOfFavoritesContained = 0,
  handleClickOnMarker,
}: {
  lat: number;
  lng: number;
  text: string;
  isDarkMode: boolean;
  isRental?: boolean;
  isFavorite?: boolean;
  containsFavorite?: boolean;
  numberOfFavoritesContained?: number;
  handleClickOnMarker: (e: google.maps.MapMouseEvent) => void;
}) {
  return (
    <div>
      <AdvancedMarker
        position={{ lat: lat, lng: lng }}
        onClick={handleClickOnMarker}
      >
        <div
          className={classNames("speech-bubble relative flex flex-row", {
            sales: !isRental,
            rentals: isRental,
          })}
        >
          <div className={"basis-4 p-1.5"}>{text}</div>
          <div className={"basis-1"}>
            {isFavorite && (
              <div
                className={
                  "relative mx-0.5 flex h-full items-center overflow-clip text-2xl"
                }
              >
                <FontAwesomeIcon
                  icon={outlineHeart}
                  className={"absolute text-black"}
                />
                <FontAwesomeIcon
                  icon={solidHeart}
                  className={"map-marker-color-filled-heart"}
                />
              </div>
            )}
            {containsFavorite && !isFavorite && (
              <div
                className={
                  "relative ml-0.5 mr-1 flex h-full items-center overflow-clip text-2xl"
                }
              >
                <FontAwesomeIcon
                  icon={outlineHeart}
                  className={
                    "cluster-marker-outline-heart absolute text-black opacity-100"
                  }
                />
                <FontAwesomeIcon
                  icon={solidHeart}
                  className={
                    "map-marker-color-filled-heart cluster-marker-solid-heart opacity-40"
                  }
                />
                <div
                  className={
                    "absolute flex w-full justify-center text-center text-auto text-xs text-black"
                  }
                >
                  {numberOfFavoritesContained}
                </div>
              </div>
            )}
          </div>
        </div>
      </AdvancedMarker>
    </div>
  );
}
