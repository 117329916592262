import React, { ReactNode, useContext, useEffect } from "react";
import { Helmet } from "react-helmet";
import { PhotoProvider, PhotoView } from "react-photo-view";
import "react-photo-view/dist/react-photo-view.css";
import { LoaderFunction, useLoaderData, useNavigate } from "react-router-dom";
import FavoriteButton from "../components/favorite_button/FavoriteButton";
import { ParcelQueryContext } from "../contexts/ParcelQueryAndParcelQueryResponseProvider";
import {
  Parcel,
  ParcelQueryResponse,
  ParcelsService,
} from "../openapi-typescript-axios";
import { formatPrice } from "../utils/PriceFormatters";

export const parcelPopupLoader: LoaderFunction = async ({
  params,
}): Promise<
  | { parcel: Parcel | undefined; picturesRootUrl: string | undefined }
  | undefined
  | null
> => {
  if (params.parcelId == null) {
    return null;
  }

  const parcelIdInt = parseInt(params.parcelId);

  const parcelQueryResponse: ParcelQueryResponse =
    await ParcelsService.getParcel(parcelIdInt);

  let parcel = parcelQueryResponse.parcels?.at(0);
  let picturesRootUrl = parcelQueryResponse.pictures_root;

  // Return both the parcel and the root URL for the pictures
  return {
    parcel,
    picturesRootUrl,
  };
};

export function ParcelInfoPopup() {
  const navigate = useNavigate();

  const parcelQuery = useContext(ParcelQueryContext);

  // Parse two return values from useLoaderData()
  const { parcel, picturesRootUrl } = useLoaderData() as {
    parcel: Parcel | undefined;
    picturesRootUrl: string | undefined;
  };

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  useEffect(() => {
    // Detect if we're on a mobile device
    if (isMobile && parcel !== undefined) {
      // Attempt to open the app
      window.location.href = `housewren://listing/${parcel.id}`;
    } else if (parcel === undefined) {
      throw new Error("Parcel not found");
    }
  }, [parcel, isMobile]);

  useEffect(() => {
    function onKeyDown(e: KeyboardEvent) {
      if (e.key === "Escape") {
        navigate("/");
      }
    }

    function onMouseDown(e: MouseEvent) {
      // Find if the mouse event took place in this component.

      let target = e.target as HTMLElement;

      if (target.closest(".parcel-map-popup") == null) {
        navigate("/");
      }
    }

    document.addEventListener("keydown", onKeyDown);
    document.addEventListener("mousedown", onMouseDown);

    return () => {
      document.removeEventListener("keydown", onKeyDown);
      document.removeEventListener("mousedown", onMouseDown);
    };
  });

  if (parcel === undefined) {
    return <div>Parcel not found</div>;
  }

  function highlightWords(text: string | null | undefined) {
    if (text == null) {
      return null;
    }

    let wordsToHighlight: string[] = parcelQuery.keywords?.split(",") ?? [];

    let segments: ReactNode[] = [text];

    wordsToHighlight.forEach((word) => {
      const regex = new RegExp(`(${word})`, "gi");
      segments = segments.flatMap((segment) =>
        typeof segment === "string"
          ? segment.split(regex).flatMap((part, i, arr) =>
              i % 2 === 0 ? (
                part
              ) : (
                <span style={{ backgroundColor: "yellow" }} key={i}>
                  {part}
                </span>
              ),
            )
          : segment,
      );
    });

    return segments;
  }

  return (
    <div className={"parcel-map-popup floating-dialog"}>
      <Helmet>
        <meta property="al:android:package" content="com.housewren.wren" />
        <meta property="al:android:app_name" content="House Wren" />
        <meta
          property="al:ios:url"
          content={`housewren://listing/${parcel.id}`}
        />
        <meta property="al:ios:app_store_id" content="1490316938" />
        <meta property="al:ios:app_name" content="House Wren" />
        <meta property="og:title" content={parcel.address ?? ""} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="http://housewren.app/" />
        <meta property="og:description" content={parcel.description ?? ""} />
        <meta
          property="og:image"
          content={picturesRootUrl + (parcel.pictures?.at(0)?.blob_name ?? "")}
        />

        <meta property="twitter:card" content="summary" />
        <meta
          property="twitter:description"
          content={parcel.description?.substring(0, 200) ?? ""}
        />
        <meta property="twitter:title" content={parcel?.address ?? ""} />
        <meta
          property="twitter:image"
          content={picturesRootUrl + (parcel.pictures?.at(0)?.blob_name ?? "")}
        />
      </Helmet>
      <div className={"flex flex-row justify-end space-x-2"}>
        <FavoriteButton parcelId={parcel.id} key={"favoritebutton"} />
        <button
          className={"float-right"}
          onClick={() => {
            navigate("/");
          }}
          key={"closebutton"}
        >
          <span>close</span>
        </button>
      </div>
      <div className={"text-2xl font-bold"}>{parcel.address}</div>
      <div className={"font-bold"}>{formatPrice(parcel.list_price)}</div>
      <div>type: {parcel.parcel_type}</div>
      <div>is_rental: {parcel.is_rental ? "yes" : "no"}</div>
      <div>Beds: {parcel.beds}</div>
      <div>Full Baths: {parcel.full_baths}</div>
      <div>Half Baths: {parcel.half_baths}</div>
      <div>Year Built: {parcel.year_built}</div>
      <div>Building Area: {parcel.living_area}</div>
      <div>Lot Area: {parcel.lot_size_acres} acres</div>

      <div className={"gray-50 #64748b pt-10 italic text-gray-400"}>
        {highlightWords(parcel.description)}
      </div>

      <div className={"office pt-20 font-bold"}>
        {parcel.mls_agent?.first_name} {parcel.mls_agent?.last_name}
      </div>
      <div className={"phone"}>{parcel.mls_agent?.phone_number}</div>
      <div className={"office"}>{parcel.mls_agent?.office.name}</div>
      <div className={"office"}>{parcel.mls_agent?.office.address}</div>
      <div className={"office pb-20"}>
        {parcel.mls_agent?.office.city}, {parcel.mls_agent?.office.state}{" "}
        {parcel.mls_agent?.office.zipcode}
      </div>

      {/*See https://react-photo-view.vercel.app/en-US/docs/getting-started#transition-effect*/}

      <PhotoProvider speed={() => 0}>
        <div>
          {parcel.pictures?.map((picture, index) => {
            if (picture.blob_name == null) {
              return null;
            }
            return (
              <PhotoView
                key={picture.blob_name}
                src={picturesRootUrl + picture.blob_name}
              >
                <img
                  className={"float-left w-1/4 cursor-pointer p-2"}
                  src={picturesRootUrl + picture.blob_name}
                  alt={picture.mls_picture_number.toString() ?? ""}
                />
              </PhotoView>
            );
          })}
        </div>
      </PhotoProvider>
    </div>
  );
}
